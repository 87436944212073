import Image from '@tiptap/extension-image'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { nodeInputRule } from '@tiptap/core'

let count = 0

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

const uploadPlaceholderPlugin = new Plugin({
  state: {
    key: new PluginKey('uploadPlaceholder'),
    init() {
      return DecorationSet.empty
    },
    apply(tr, set) {
      // Adjust decoration positions to changes made by the transaction
      set = set.map(tr.mapping, tr.doc)
      // See if the transaction adds or removes any placeholders
      let action = tr.getMeta('uploadPlaceholder')
      if (action && action.add) {
        let widget = document.createElement('img')
        widget.src = action.add.imagePreview
        widget.setAttribute('id', action.add.id)
        widget.className = 'preview'
        let deco = Decoration.widget(action.add.pos, widget, {
          id: action.add.id,
        })
        set = set.add(tr.doc, [deco])
      } else if (action && action.remove) {
        set = set.remove(
          set.find(null, null, (spec) => spec.id == action.remove.id)
        )
      }
      return set
    },
  },
  props: {
    decorations(state) {
      return this.getState(state)
    },
  },
})

function finduploadPlaceholder(state, id) {
  let decos = uploadPlaceholderPlugin.getState(state)
  let found = decos.find(null, null, (spec) => spec.id == id)
  return found.length ? found[0].from : null
}

function handleUploads({ files, view, attachImageFile, coordinates }) {
  Array.from(files || []).forEach((file) => {
    if (/image/i.test(file.type)) {
      insertImage(file, view, coordinates, attachImageFile)
    }
  })
}

export async function insertImage(file, view, coordinates, attachImageFile) {
  const { schema } = view.state

  const id = 'image' + count++
  // Replace the selection with a placeholder
  let tr = view.state.tr

  const fileReader = new FileReader()
  fileReader.onload = (e) => {
    let imagePreview = e.target.result
    if (!tr.selection.empty) {
      tr.deleteSelection()
      tr.setMeta('uploadPlaceholder', {
        add: { id, pos: tr.selection.from, imagePreview },
      })
    } else if (coordinates) {
      tr.setMeta('uploadPlaceholder', {
        add: { id, pos: coordinates.pos, imagePreview },
      })
    } else {
      tr.setMeta('uploadPlaceholder', {
        add: { id, pos: tr.selection.from, imagePreview },
      })
    }
    view.dispatch(tr)
  }
  fileReader.readAsDataURL(file)

  attachImageFile({
    file: file,
    onComplete: (url, filename, cdnTransform) => {
      var img = document.createElement('img')
      img.src = url
      img.onload = function () {
        var cdnImg = document.createElement('img')
        const cdnUrl = cdnTransform(
          filename,
          img.naturalWidth,
          img.naturalHeight
        )
        cdnImg.src = cdnUrl
        cdnImg.onload = function () {
          let pos = finduploadPlaceholder(view.state, id)
          // If the content around the placeholder has been deleted, drop
          // the image
          if (pos == null) return
          // Otherwise, insert it at the placeholder's position, and remove
          // the placeholder
          const cdnUrl = cdnTransform(
            filename,
            img.naturalWidth,
            img.naturalHeight
          )
          view.dispatch(
            view.state.tr
              .replaceWith(
                pos,
                pos,
                schema.nodes.image.create({
                  src: cdnUrl,
                  height: img.naturalHeight,
                  width: img.naturalWidth,
                })
              )
              .setMeta('uploadPlaceholder', { remove: { id } })
          )
        }
      }
    },
    onFailure: () => {
      // On failure, just clean up the placeholder
      view.dispatch(tr.setMeta('uploadPlaceholder', { remove: { id } }))
    },
  })
}

export const CustomImage = Image.extend({
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
    }
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, alt, src, title, width, height] = match
          return { src, alt, title, width, height }
        },
      }),
    ]
  },
  addProseMirrorPlugins() {
    const attachImageFile = this.options.attachImageFile
    return [
      uploadPlaceholderPlugin,
      new Plugin({
        props: {
          handleDOMEvents: {
            paste(view, event) {
              const items = Array.from(event.clipboardData.items)

              if (items.filter((item) => item.getAsFile()).length == 0) {
                return
              }

              event.preventDefault()
              const files = items.map(
                (item) =>
                  new File(
                    [item.getAsFile()],
                    event.clipboardData.getData('text/plain') || Date.now(),
                    { lastModified: Date.now(), type: item.type }
                  )
              )
              const coordinates = null
              handleUploads({
                files,
                view,
                attachImageFile,
                coordinates,
              })
            },
            drop(view, event) {
              const hasFiles =
                event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length

              if (!hasFiles) {
                return
              }
              event.preventDefault()

              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              })

              handleUploads({
                files: event.dataTransfer.files,
                view,
                attachImageFile,
                coordinates,
              })
            },
          },
        },
      }),
    ]
  },
})
