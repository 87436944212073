<script>
import {
  BBadge,
  BButtonToolbar,
  BButtonGroup,
  BFormFile,
  BFormTextarea,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BFormSelectOption,
  BFormTags,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BIconCalendar,
  BIconPlus,
  BIconTypeBold,
  BIconTypeItalic,
  BIconTypeH1,
  BIconTypeH2,
  BIconTypeH3,
  BIconListUl,
  BIconListOl,
  BIconImage,
  BIconArrowLeftRight,
  BIconArrowClockwise,
  BIconArrowCounterclockwise,
} from 'bootstrap-vue'

import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Heading from '@tiptap/extension-heading'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Link from '@tiptap/extension-link'
import History from '@tiptap/extension-history'
// import Image from '@tiptap/extension-image'
import { CustomImage, insertImage } from './tiptapImageExtension'

import { global } from './utils'

export default {
  name: 'EditEvent',
  components: {
    EditorContent,
    BBadge,
    BButtonGroup,
    BButtonToolbar,
    BFormFile,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormSelect,
    BFormSelectOption,
    BFormTags,
    BFormTimepicker,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BIconCalendar,
    BIconPlus,
    BIconTypeBold,
    BIconTypeItalic,
    BIconTypeH1,
    BIconTypeH2,
    BIconTypeH3,
    BIconListUl,
    BIconListOl,
    BIconImage,
    BIconArrowLeftRight,
    BIconArrowClockwise,
    BIconArrowCounterclockwise,
  },
  props: {
    user: [Object, String],
    campaignProp: Object,
    categoriesProp: Array,
    popularTagsProp: Array,
    language: String,
    editorPlaceholder: String,
  },
  data() {
    return {
      editor: null,
      global,
      mainImage: null,
      mainImagePreview: null,
      errors: {},
      loadings: {},
      event: {},
      previewHeight: 'inherit',
      previewLanguage: null,
      eventTitlePreview: {},
      eventShortDescriptionPreview: {},
      eventLongDescriptionPreview: {},
      eventTaglinePreview: {},
      eventLocationPreview: {
        text: '',
        url: '',
      },
      eventExternalLinkPreview: {
        text: '',
        url: '',
      },
      eventTicketLinkPreview: {
        url: '',
      },
      eventDatePreview: {
        type: 'permanent',
        start: '',
        end: '',
        hour: '20:00:00',
      },
      mainImageCaptionPreview: '',
      eventStagePreview: null,
      eventCategoriesPreview: null,
      categories: [],
      eventTagsPreview: [],
      reviewComment: '',
      popularTags: [],
      editorImage: null,
      showEventTickets: false,
      eventTicketsPreview: [],
    }
  },
  methods: {
    setError(name) {
      this.$set(this.errors, name, true)
    },
    clearError(name) {
      this.$set(this.errors, name, false)
    },
    setLoading(name) {
      this.$set(this.loadings, name, true)
    },
    clearLoading(name) {
      this.$set(this.loadings, name, false)
    },
    uploadImage() {
      if (this.canEditEvent) {
        this.mainImage && this.uploadImageFileEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    uploadImageFileEndpoint() {
      this.setLoading('uploadImageFileEndpoint')
      this.clearError('uploadImageFileEndpoint')
      let body = new window.FormData()
      body.append('file', this.mainImage)
      this.axios
        .post(this.$root.apiUrl + '/agitator/files/images/', body)
        .then((response) => {
          this.global.debug(response.data)
          response.data.image &&
            response.data.image.filename &&
            this.setCampaignMainImageEndpoint(response.data.image.filename)
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('uploadImageFileEndpoint')
          this.clearLoading('uploadImageFileEndpoint')
        })
        .finally(() => this.clearLoading('uploadImageFileEndpoint'))
    },
    setCampaignMainImageEndpoint(imageName) {
      this.setLoading('setCampaignMainImageEndpoint')
      this.clearError('setCampaignMainImageEndpoint')
      this.global.debug('setting event image to: ' + imageName)
      const body = {
        settings: {
          project_pic: imageName,
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/settings/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          this.$set(this.event, 'main_image', imageName)
          this.resetMainImagePreview()
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('setCampaignMainImageEndpoint')
          this.clearLoading('setCampaignMainImageEndpoint')
        })
        .finally(() => this.clearLoading('setCampaignMainImageEndpoint'))
    },
    resetEventTitle() {
      if (this.isEventTitleModified) {
        this.eventTitlePreview = JSON.parse(JSON.stringify(this.event.title))
      }
    },
    saveEventLanguage() {
      if (this.canEditEvent) {
        let patchBody = {}
        if (this.isEventTitleModified) {
          patchBody.title = this.eventTitlePreview
        }
        if (this.isEventLongDescriptionModified) {
          patchBody.long_description = this.eventLongDescriptionPreview
        }
        if (Object.keys(patchBody).length > 0) {
          this.saveEventLanguageEndpoint(patchBody)
        }
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventLanguageEndpoint(patchBody) {
      this.setLoading('saveEventLanguageEndpoint')
      this.clearError('saveEventLanguageEndpoint')
      let debugMessage = this.isEventTitleModified
        ? 'title: ' + this.eventTitlePreview[this.previewLanguage].value + ' '
        : ''
      this.isEventLongDescriptionModified
        ? (debugMessage +=
            'long description: ' +
            this.eventLongDescriptionPreview[this.previewLanguage].value +
            ' ')
        : false
      this.global.debug(debugMessage)
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/language/',
          patchBody,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (this.isEventTitleModified) {
            if (
              this.event.title &&
              this.event.title[this.previewLanguage] &&
              this.event.title[this.previewLanguage].value
            ) {
              this.$set(
                this.event.title[this.previewLanguage],
                'value',
                this.eventTitlePreview[this.previewLanguage].value
              )
            } else {
              const item = {
                [this.previewLanguage]: {
                  value: this.eventTitlePreview[this.previewLanguage].value,
                },
              }
              this.$set(
                this.event.title,
                [this.previewLanguage],
                item[this.previewLanguage]
              )
            }
            if (this.eventTitlePreview[this.previewLanguage].value === '') {
              this.$delete(this.eventTitlePreview, [this.previewLanguage])
              this.$delete(this.event.title, [this.previewLanguage])
            }
          }
          if (this.isEventLongDescriptionModified) {
            if (
              this.event.long_description &&
              this.event.long_description[this.previewLanguage] &&
              this.event.long_description[this.previewLanguage].value
            ) {
              this.$set(
                this.event.long_description[this.previewLanguage],
                'value',
                this.eventLongDescriptionPreview[this.previewLanguage].value
              )
            } else {
              const item = {
                [this.previewLanguage]: {
                  value:
                    this.eventLongDescriptionPreview[this.previewLanguage]
                      .value,
                },
              }
              this.$set(
                this.event.long_description,
                [this.previewLanguage],
                item[this.previewLanguage]
              )
            }
            if (
              this.eventLongDescriptionPreview[this.previewLanguage].value ===
              ''
            ) {
              this.$delete(this.eventLongDescriptionPreview, [
                this.previewLanguage,
              ])
              this.$delete(this.event.long_description, [this.previewLanguage])
            }
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventLanguageEndpoint')
          this.clearLoading('saveEventLanguageEndpoint')
        })
        .finally(() => this.clearLoading('saveEventLanguageEndpoint'))
    },
    saveEventTitle() {
      if (this.canEditEvent) {
        this.isEventTitleModified && this.saveEventTitleEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventTitleEndpoint() {
      this.setLoading('saveEventTitleEndpoint')
      this.clearError('saveEventTitleEndpoint')
      this.global.debug(
        'setting event title to: ' +
          this.eventTitlePreview[this.previewLanguage].value
      )
      const body = { title: this.eventTitlePreview }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/language/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (
            this.event.title &&
            this.event.title[this.previewLanguage] &&
            this.event.title[this.previewLanguage].value
          ) {
            this.$set(
              this.event.title[this.previewLanguage],
              'value',
              this.eventTitlePreview[this.previewLanguage].value
            )
          } else {
            const item = {
              [this.previewLanguage]: {
                value: this.eventTitlePreview[this.previewLanguage].value,
              },
            }
            this.$set(
              this.event.title,
              [this.previewLanguage],
              item[this.previewLanguage]
            )
          }
          if (this.eventTitlePreview[this.previewLanguage].value === '') {
            this.$delete(this.eventTitlePreview, [this.previewLanguage])
            this.$delete(this.event.title, [this.previewLanguage])
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventTitleEndpoint')
          this.clearLoading('saveEventTitleEndpoint')
        })
        .finally(() => this.clearLoading('saveEventTitleEndpoint'))
    },
    addEventTicketEndpoint() {
      this.setLoading('addEventTicketEndpoint')
      this.clearError('addEventTicketEndpoint')
      this.global.debug('adding a new event ticket')
      this.axios
        .post(
          this.$root.apiUrl +
            '/agitator/campaigns/' +
            this.event.id +
            '/rewards',
          {},
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          const newReward = {
            id: response.data.reward.reward_id,
            amount: 1,
            title: {},
            description: {},
          }
          if (this.event.tickets) {
            let currentTickets = this.event.tickets
            currentTickets.unshift(newReward)
            this.$set(this.event, 'tickets', currentTickets)
          } else {
            this.$set(this.event, 'tickets', [newReward])
          }
          this.eventTicketsPreview = JSON.parse(
            JSON.stringify(this.event.tickets)
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('addEventTicketEndpoint')
          this.clearLoading('addEventTicketEndpoint')
        })
        .finally(() => this.clearLoading('addEventTicketEndpoint'))
    },
    removeEventTicket(id) {
      let currentTickets = this.event.tickets
      currentTickets = currentTickets.filter((ticket) => ticket.id !== id)
      this.$set(this.event, 'tickets', currentTickets)
      this.eventTicketsPreview = JSON.parse(JSON.stringify(this.event.tickets))
    },
    saveEventShortDescription() {
      if (this.canEditEvent) {
        this.isEventShortDescriptionModified &&
          this.saveEventShortDescriptionEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventShortDescriptionEndpoint() {
      this.setLoading('saveEventShortDescriptionEndpoint')
      this.clearError('saveEventShortDescriptionEndpoint')
      this.global.debug(
        'setting event short description to: ' +
          this.eventShortDescriptionPreview[this.previewLanguage].value
      )
      const body = { short_description: this.eventShortDescriptionPreview }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/language/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (
            this.event.short_description &&
            this.event.short_description[this.previewLanguage] &&
            this.event.short_description[this.previewLanguage].value
          ) {
            this.$set(
              this.event.short_description[this.previewLanguage],
              'value',
              this.eventShortDescriptionPreview[this.previewLanguage].value
            )
          } else {
            const item = {
              [this.previewLanguage]: {
                value:
                  this.eventShortDescriptionPreview[this.previewLanguage].value,
              },
            }
            this.$set(
              this.event.short_description,
              [this.previewLanguage],
              item[this.previewLanguage]
            )
          }
          if (
            this.eventShortDescriptionPreview[this.previewLanguage].value === ''
          ) {
            this.$delete(this.eventShortDescriptionPreview, [
              this.previewLanguage,
            ])
            this.$delete(this.event.short_description, [this.previewLanguage])
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventShortDescriptionEndpoint')
          this.clearLoading('saveEventShortDescriptionEndpoint')
        })
        .finally(() => this.clearLoading('saveEventShortDescriptionEndpoint'))
    },
    resetEventLongDescription() {
      if (this.isEventLongDescriptionModified) {
        this.eventLongDescriptionPreview = JSON.parse(
          JSON.stringify(this.event.long_description)
        )
      }
    },
    saveEventLongDescription() {
      if (this.canEditEvent) {
        this.isEventLongDescriptionModified &&
          this.saveEventLongDescriptionEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventLongDescriptionEndpoint() {
      this.setLoading('saveEventLongDescriptionEndpoint')
      this.clearError('saveEventLongDescriptionEndpoint')
      this.global.debug(
        'setting event long description to: ' +
          this.eventLongDescriptionPreview[this.previewLanguage].value
      )
      const body = { long_description: this.eventLongDescriptionPreview }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/language/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (
            this.event.long_description &&
            this.event.long_description[this.previewLanguage] &&
            this.event.long_description[this.previewLanguage].value
          ) {
            this.$set(
              this.event.long_description[this.previewLanguage],
              'value',
              this.eventLongDescriptionPreview[this.previewLanguage].value
            )
          } else {
            const item = {
              [this.previewLanguage]: {
                value:
                  this.eventLongDescriptionPreview[this.previewLanguage].value,
              },
            }
            this.$set(
              this.event.long_description,
              [this.previewLanguage],
              item[this.previewLanguage]
            )
          }
          if (
            this.eventLongDescriptionPreview[this.previewLanguage].value === ''
          ) {
            this.$delete(this.eventLongDescriptionPreview, [
              this.previewLanguage,
            ])
            this.$delete(this.event.long_description, [this.previewLanguage])
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventLongDescriptionEndpoint')
          this.clearLoading('saveEventLongDescriptionEndpoint')
        })
        .finally(() => this.clearLoading('saveEventLongDescriptionEndpoint'))
    },
    saveEventTagline() {
      if (this.canEditEvent) {
        this.isEventTaglineModified && this.saveEventTaglineEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventTaglineEndpoint() {
      this.setLoading('saveEventTaglineEndpoint')
      this.clearError('saveEventTaglineEndpoint')
      this.global.debug(
        'setting event tagline to: ' +
          this.eventTaglinePreview[this.previewLanguage].value
      )
      const body = { tagline: this.eventTaglinePreview }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/language/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (
            this.event.tagline &&
            this.event.tagline[this.previewLanguage] &&
            this.event.tagline[this.previewLanguage].value
          ) {
            this.$set(
              this.event.tagline[this.previewLanguage],
              'value',
              this.eventTaglinePreview[this.previewLanguage].value
            )
          } else {
            const item = {
              [this.previewLanguage]: {
                value: this.eventTaglinePreview[this.previewLanguage].value,
              },
            }
            this.$set(
              this.event.tagline,
              [this.previewLanguage],
              item[this.previewLanguage]
            )
          }
          if (this.eventTaglinePreview[this.previewLanguage].value === '') {
            this.$delete(this.eventTaglinePreview, [this.previewLanguage])
            this.$delete(this.event.tagline, [this.previewLanguage])
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventTaglineEndpoint')
          this.clearLoading('saveEventTaglineEndpoint')
        })
        .finally(() => this.clearLoading('saveEventTaglineEndpoint'))
    },
    resetEventLocation() {
      if (this.isEventLocationModified) {
        this.eventLocationPreview = this.getMetaByKey(
          this.event.meta,
          'location'
        )
          ? this.getMetaByKey(this.event.meta, 'location')
          : {
              text: '',
              url: '',
            }
      }
    },
    saveEventMeta() {
      if (this.canEditEvent) {
        let patchBody = {}
        if (this.isEventLocationModified) {
          patchBody.location = {
            text: this.eventLocationPreview.text,
            url: this.eventLocationPreview.url,
          }
        }
        if (this.isEventExternalLinkModified) {
          patchBody.externalLink = {
            text: this.eventExternalLinkPreview.text,
            url: this.eventExternalLinkPreview.url,
          }
        }
        if (this.isMainImageCaptionModified) {
          patchBody.mainImageCaption = this.mainImageCaptionPreview
        }
        if (this.isEventTicketLinkModified) {
          patchBody.ticketLink = {
            url: this.eventTicketLinkPreview.url,
          }
        }
        if (this.isEventDateModified) {
          if (this.eventDatePreview.type === 'permanent') {
            this.eventDatePreview.start =
              this.eventDatePreview.end =
              this.eventDatePreview.hour =
                ''
          } else if (this.eventDatePreview.type === 'single') {
            this.eventDatePreview.start = this.eventDatePreview.end
          }
          patchBody.date = {
            start: this.eventDatePreview.start,
            end: this.eventDatePreview.end,
            type: this.eventDatePreview.type,
            hour: this.eventDatePreview.hour,
          }
        }
        if (Object.keys(patchBody).length > 0) {
          this.saveEventMetaEndpoint(patchBody)
        }
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventMetaEndpoint(patchBody) {
      this.setLoading('saveEventMetaEndpoint')
      this.clearError('saveEventMetaEndpoint')
      let debugMessage = this.isEventLocationModified
        ? 'location: ' +
          this.eventLocationPreview.text +
          ', ' +
          this.eventLocationPreview.url +
          ' '
        : ''
      this.isEventExternalLinkModified
        ? (debugMessage +=
            'external link: ' +
            this.eventExternalLinkPreview.text +
            ', ' +
            this.eventExternalLinkPreview.url +
            ' ')
        : false
      this.isMainImageCaptionModified
        ? (debugMessage +=
            'main image caption: ' + this.mainImageCaptionPreview + ' ')
        : false
      this.isEventTicketLinkModified
        ? (debugMessage +=
            'ticket link: ' + this.eventTicketLinkPreview.url + ' ')
        : false
      this.isEventDateModified
        ? (debugMessage +=
            'date: ' +
            this.eventDatePreview.start +
            ' - ' +
            this.eventDatePreview.hour +
            ' - ' +
            this.eventDatePreview.end +
            ' ')
        : false
      this.global.debug(debugMessage)
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/meta/',
          { meta: patchBody },
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (this.isEventLocationModified) {
            this.$set(
              this.event.meta,
              'location',
              JSON.parse(JSON.stringify(this.eventLocationPreview))
            )
          }
          if (this.isEventExternalLinkModified) {
            this.$set(
              this.event.meta,
              'externalLink',
              JSON.parse(JSON.stringify(this.eventExternalLinkPreview))
            )
          }
          if (this.isMainImageCaptionModified) {
            this.$set(
              this.event.meta,
              'mainImageCaption',
              JSON.parse(JSON.stringify(this.mainImageCaptionPreview))
            )
          }
          if (this.isEventTicketLinkModified) {
            this.$set(
              this.event.meta,
              'ticketLink',
              JSON.parse(JSON.stringify(this.eventTicketLinkPreview))
            )
          }
          if (this.isEventDateModified) {
            this.$set(
              this.event.meta,
              'date',
              JSON.parse(JSON.stringify(this.eventDatePreview))
            )
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventMetaEndpoint')
          this.clearLoading('saveEventMetaEndpoint')
        })
        .finally(() => this.clearLoading('saveEventMetaEndpoint'))
    },
    saveEventLocation() {
      if (this.canEditEvent) {
        this.isEventLocationModified && this.saveEventLocationEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventLocationEndpoint() {
      this.setLoading('saveEventLocationEndpoint')
      this.clearError('saveEventLocationEndpoint')
      this.global.debug(
        'setting event location to: ' +
          this.eventLocationPreview.text +
          ', ' +
          this.eventLocationPreview.url
      )
      const body = {
        meta: {
          location: {
            text: this.eventLocationPreview.text,
            url: this.eventLocationPreview.url,
          },
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/meta/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(
            this.event.meta,
            'location',
            JSON.parse(JSON.stringify(this.eventLocationPreview))
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventLocationEndpoint')
          this.clearLoading('saveEventLocationEndpoint')
        })
        .finally(() => this.clearLoading('saveEventLocationEndpoint'))
    },
    resetEventExternalLink() {
      if (this.isEventExternalLinkModified) {
        this.eventExternalLinkPreview = this.getMetaByKey(
          this.event.meta,
          'externalLink'
        )
          ? this.getMetaByKey(this.event.meta, 'externalLink')
          : {
              text: '',
              url: '',
            }
      }
    },
    saveEventExternalLink() {
      if (this.canEditEvent) {
        this.isEventExternalLinkModified && this.saveEventExternalLinkEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventExternalLinkEndpoint() {
      this.setLoading('saveEventExternalLinkEndpoint')
      this.clearError('saveEventExternalLinkEndpoint')
      this.global.debug(
        'setting event external link to: ' +
          this.eventExternalLinkPreview.text +
          ', ' +
          this.eventExternalLinkPreview.url
      )
      const body = {
        meta: {
          externalLink: {
            text: this.eventExternalLinkPreview.text,
            url: this.eventExternalLinkPreview.url,
          },
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/meta/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(
            this.event.meta,
            'externalLink',
            JSON.parse(JSON.stringify(this.eventExternalLinkPreview))
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventExternalLinkEndpoint')
          this.clearLoading('saveEventExternalLinkEndpoint')
        })
        .finally(() => this.clearLoading('saveEventExternalLinkEndpoint'))
    },
    resetMainImageCaption() {
      if (this.isMainImageCaptionModified) {
        this.mainImageCaptionPreview = this.getMetaByKey(
          this.event.meta,
          'mainImageCaption'
        )
          ? this.getMetaByKey(this.event.meta, 'mainImageCaption')
          : ''
      }
    },
    saveMainImageCaption() {
      if (this.canEditEvent) {
        this.isMainImageCaptionModified && this.saveMainImageCaptionEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveMainImageCaptionEndpoint() {
      this.setLoading('saveMainImageCaptionEndpoint')
      this.clearError('saveMainImageCaptionEndpoint')
      this.global.debug(
        'setting main image caption to: ' + this.mainImageCaptionPreview
      )
      const body = {
        meta: {
          mainImageCaption: this.mainImageCaptionPreview,
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/meta/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(
            this.event.meta,
            'mainImageCaption',
            JSON.parse(JSON.stringify(this.mainImageCaptionPreview))
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveMainImageCaptionEndpoint')
          this.clearLoading('saveMainImageCaptionEndpoint')
        })
        .finally(() => this.clearLoading('saveMainImageCaptionEndpoint'))
    },
    saveEventTicketLink() {
      if (this.canEditEvent) {
        this.isEventTicketLinkModified && this.saveEventTicketLinkEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventTicketLinkEndpoint() {
      this.setLoading('saveEventTicketLinkEndpoint')
      this.clearError('saveEventTicketLinkEndpoint')
      this.global.debug(
        'setting event ticket link to: ' + this.eventTicketLinkPreview.url
      )
      const body = {
        meta: {
          ticketLink: {
            url: this.eventTicketLinkPreview.url,
          },
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/meta/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(
            this.event.meta,
            'ticketLink',
            JSON.parse(JSON.stringify(this.eventTicketLinkPreview))
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventTicketLinkEndpoint')
          this.clearLoading('saveEventTicketLinkEndpoint')
        })
        .finally(() => this.clearLoading('saveEventTicketLinkEndpoint'))
    },
    resetEventDate() {
      if (this.isEventDateModified) {
        this.eventDatePreview = this.getMetaByKey(this.event.meta, 'date')
          ? this.getMetaByKey(this.event.meta, 'date')
          : {
              type: 'permanent',
              start: '',
              end: '',
              hour: '20:00:00',
            }
      }
    },
    saveEventDate() {
      if (this.canEditEvent) {
        this.isEventDateModified && this.saveEventDateEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventDateEndpoint() {
      this.setLoading('saveEventDateEndpoint')
      this.clearError('saveEventDateEndpoint')
      if (this.eventDatePreview.type === 'permanent') {
        this.eventDatePreview.start =
          this.eventDatePreview.end =
          this.eventDatePreview.hour =
            ''
        this.global.debug('setting event date to: permanent')
      } else if (this.eventDatePreview.type === 'single') {
        this.eventDatePreview.start = this.eventDatePreview.end
        this.global.debug(
          'setting event date to: ' +
            this.eventDatePreview.end +
            ' @ ' +
            this.eventDatePreview.hour
        )
      } else {
        this.global.debug(
          'setting event date range to: ' +
            this.eventDatePreview.start +
            ' - ' +
            this.eventDatePreview.end
        )
      }
      const body = {
        meta: {
          date: {
            start: this.eventDatePreview.start,
            end: this.eventDatePreview.end,
            type: this.eventDatePreview.type,
            hour: this.eventDatePreview.hour,
          },
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/meta/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(
            this.event.meta,
            'date',
            JSON.parse(JSON.stringify(this.eventDatePreview))
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventDateEndpoint')
          this.clearLoading('saveEventDateEndpoint')
        })
        .finally(() => this.clearLoading('saveEventDateEndpoint'))
    },
    saveEventStage() {
      if (this.canEditEvent) {
        this.isEventStageModified && this.saveEventStageEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventStageEndpoint() {
      this.setLoading('saveEventStageEndpoint')
      this.clearError('saveEventStageEndpoint')
      this.global.debug('setting event stage to: ' + this.eventStagePreview)
      const body = {
        admin: {
          stage: this.eventStagePreview,
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/admin/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.event.stage = this.eventStagePreview
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventStageEndpoint')
          this.clearLoading('saveEventStageEndpoint')
        })
        .finally(() => this.clearLoading('saveEventStageEndpoint'))
    },
    resetEventCategories() {
      if (this.isEventCategoriesModified) {
        this.eventCategoriesPreview = JSON.parse(
          JSON.stringify(this.event.categories)
        )
      }
    },
    saveEventCategories() {
      if (this.canEditEvent) {
        this.isEventCategoriesModified && this.saveEventCategoriesEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventCategoriesEndpoint() {
      this.setLoading('saveEventCategoriesEndpoint')
      this.clearError('saveEventCategoriesEndpoint')
      this.global.debug(
        'setting event categories to: ' + this.getEventCategoriesIds()
      )
      const body = {
        categories: this.getEventCategoriesIds(),
      }
      this.axios
        .post(
          this.$root.apiUrl +
            '/agitator/campaigns/' +
            this.event.id +
            '/categories/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.event.categories = JSON.parse(
            JSON.stringify(this.eventCategoriesPreview)
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventCategoriesEndpoint')
          this.clearLoading('saveEventCategoriesEndpoint')
        })
        .finally(() => this.clearLoading('saveEventCategoriesEndpoint'))
    },
    addEventCategory(id) {
      this.eventCategoriesPreview.push(this.getCategoryById(id))
    },
    removeEventCategory(id) {
      this.eventCategoriesPreview = this.eventCategoriesPreview.filter(
        (category) => category.id !== id
      )
    },
    saveEventTags() {
      if (this.canEditEvent) {
        this.isEventTagsModified && this.saveEventTagsEndpoint()
      } else {
        this.global.debug('editing not allowed')
      }
    },
    saveEventTagsEndpoint() {
      this.setLoading('saveEventTagsEndpoint')
      this.clearError('saveEventTagsEndpoint')
      this.global.debug('setting event tags to: ' + this.eventTagsPreview)
      const body = {
        meta: {
          tags: this.eventTagsPreview,
        },
      }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/campaigns/' +
            this.event.id +
            '/meta/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(
            this.event.meta,
            'tags',
            JSON.parse(JSON.stringify(this.eventTagsPreview))
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveEventTagsEndpoint')
          this.clearLoading('saveEventTagsEndpoint')
        })
        .finally(() => this.clearLoading('saveEventTagsEndpoint'))
    },
    requestReview() {
      this.requestReviewEndpoint()
    },
    requestReviewEndpoint() {
      this.setLoading('requestReviewEndpoint')
      this.clearError('requestReviewEndpoint')
      this.global.debug('requesting event review')
      this.axios
        .post(
          this.$root.apiUrl +
            '/agitator/campaigns/' +
            this.event.id +
            '/request_review/',
          {},
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(this.event.meta, 'requestReview', true)
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('requestReviewEndpoint')
          this.clearLoading('requestReviewEndpoint')
        })
        .finally(() => this.clearLoading('requestReviewEndpoint'))
    },
    resetRequestReview() {
      this.resetRequestReviewEndpoint()
    },
    resetRequestReviewEndpoint() {
      this.setLoading('resetRequestReviewEndpoint')
      this.clearError('resetRequestReviewEndpoint')
      this.global.debug('resetting event review request')
      const body = {
        comment: this.reviewComment,
      }
      this.axios
        .post(
          this.$root.apiUrl +
            '/agitator/campaigns/' +
            this.event.id +
            '/reject_review/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(this.event.meta, 'requestReview', false)
          window.location = '/admin/events/review'
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('resetRequestReviewEndpoint')
          this.clearLoading('resetRequestReviewEndpoint')
        })
        .finally(() => this.clearLoading('resetRequestReviewEndpoint'))
    },
    publishEvent() {
      this.publishEventEndpoint()
    },
    publishEventEndpoint() {
      this.setLoading('publishEventEndpoint')
      this.clearError('publishEventEndpoint')
      this.global.debug('publishing event')
      this.axios
        .post(
          this.$root.apiUrl +
            '/agitator/campaigns/' +
            this.event.id +
            '/publish/',
          {},
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.$set(this.event, 'stage', 2)
          this.eventStagePreview = this.event.stage
          this.$set(this.event.meta, 'requestReview', false)
          window.location = '/admin/events/review'
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('publishEventEndpoint')
          this.clearLoading('publishEventEndpoint')
        })
        .finally(() => this.clearLoading('publishEventEndpoint'))
    },
    deleteEvent(confirmText = false) {
      let confirm = false
      if (confirmText) {
        confirm = window.confirm(confirmText)
      } else {
        confirm = window.confirm('This action cannot be undone, are you sure?')
      }
      if (confirm) {
        this.deleteEventEndpoint()
      } else {
        this.global.debug('deleteEvent canceled')
      }
    },
    deleteEventEndpoint() {
      this.setLoading('deleteEventEndpoint')
      this.clearError('deleteEventEndpoint')
      this.global.debug('deleting event')
      this.axios
        .delete(
          this.$root.apiUrl + '/agitator/events/' + this.event.id,
          {},
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          if (this.isUserAdmin) {
            window.location = '/admin/events'
          } else {
            window.location = '/me/events'
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('deleteEventEndpoint')
          this.clearLoading('deleteEventEndpoint')
        })
        .finally(() => this.clearLoading('deleteEventEndpoint'))
    },
    unpublishEvent(confirmText = false) {
      let confirm = false
      if (confirmText) {
        confirm = window.confirm(confirmText)
      } else {
        confirm = window.confirm('This action cannot be undone, are you sure?')
      }
      if (confirm) {
        this.unpublishEventEndpoint()
      } else {
        this.global.debug('unpublishEvent canceled')
      }
    },
    unpublishEventEndpoint() {
      this.setLoading('unpublishEventEndpoint')
      this.clearError('unpublishEventEndpoint')
      this.global.debug('unpublishing event')
      this.axios
        .post(
          this.$root.apiUrl +
            '/agitator/events/' +
            this.event.id +
            '/unpublish',
          {},
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          this.event.stage = 0
          this.eventStagePreview = this.event.stage
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('unpublishEventEndpoint')
          this.clearLoading('unpublishEventEndpoint')
        })
        .finally(() => this.clearLoading('unpublishEventEndpoint'))
    },
    getImagePreview(imageData) {
      if (imageData) {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          this.mainImagePreview = e.target.result
        }
        fileReader.readAsDataURL(imageData)
      }
    },
    resetMainImagePreview() {
      this.mainImagePreview = null
      this.mainImage = null
    },
    setEventTitlePreview(value) {
      if (this.eventTitlePreview[this.previewLanguage]) {
        this.$set(this.eventTitlePreview[this.previewLanguage], 'value', value)
      } else {
        const item = {
          [this.previewLanguage]: {
            value: value,
          },
        }
        this.$set(
          this.eventTitlePreview,
          [this.previewLanguage],
          item[this.previewLanguage]
        )
      }
    },
    setEventShortDescriptionPreview(value) {
      if (this.eventShortDescriptionPreview[this.previewLanguage]) {
        this.$set(
          this.eventShortDescriptionPreview[this.previewLanguage],
          'value',
          value
        )
      } else {
        const item = {
          [this.previewLanguage]: {
            value: value,
          },
        }
        this.$set(
          this.eventShortDescriptionPreview,
          [this.previewLanguage],
          item[this.previewLanguage]
        )
      }
    },
    setEventLongDescriptionPreview(value) {
      if (this.eventLongDescriptionPreview[this.previewLanguage]) {
        this.$set(
          this.eventLongDescriptionPreview[this.previewLanguage],
          'value',
          value
        )
      } else {
        const item = {
          [this.previewLanguage]: {
            value: value,
          },
        }
        this.$set(
          this.eventLongDescriptionPreview,
          [this.previewLanguage],
          item[this.previewLanguage]
        )
      }
    },
    setEventTaglinePreview(value) {
      if (this.eventTaglinePreview[this.previewLanguage]) {
        this.$set(
          this.eventTaglinePreview[this.previewLanguage],
          'value',
          value
        )
      } else {
        const item = {
          [this.previewLanguage]: {
            value: value,
          },
        }
        this.$set(
          this.eventTaglinePreview,
          [this.previewLanguage],
          item[this.previewLanguage]
        )
      }
    },
    getMetaByKey(meta, key) {
      if (Object.prototype.hasOwnProperty.call(meta, key)) {
        return JSON.parse(JSON.stringify(meta[key]))
      } else {
        return false
      }
    },
    isValidUrl(value) {
      if (
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/.test(
          value
        )
      ) {
        return true
      } else {
        return false
      }
    },
    formatDateFromString(dateString, locale) {
      const date = new Date(dateString)
      return (
        dateString &&
        new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(date)
      )
    },
    formatHourFromString(hourString, locale) {
      const date = new Date('1970-01-01T' + hourString)
      return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
      }).format(date)
    },
    getEventCategoriesIds() {
      return this.eventCategoriesPreview.map((category) => category.id)
    },
    getCategoryById(id) {
      return this.categories.find((category) => category.id === id)
    },
    getCategoriesEndpoint() {
      this.setLoading('getCategoriesEndpoint')
      this.clearError('getCategoriesEndpoint')
      this.axios
        .get(this.$root.apiUrl + '/agitator/campaigns/categories/')
        .then((response) => {
          this.global.debug(response.data)
          this.categories = JSON.parse(JSON.stringify(response.data.categories))
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('getCategoriesEndpoint')
          this.clearLoading('getCategoriesEndpoint')
        })
        .finally(() => this.clearLoading('getCategoriesEndpoint'))
    },
    saveEvent() {
      if (this.hasUnsavedChanges) {
        this.saveEventLanguage()
        this.saveEventMeta()
        this.saveEventCategories()
        this.saveEventStage()
        this.uploadImage()
        this.saveEventTickets()
      }
    },
    beforeWindowUnload(e) {
      if (this.hasUnsavedChanges) {
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
    sortEventTagsByFrequency(tags) {
      return tags.sort((a, b) => {
        return parseInt(b.frequency) - parseInt(a.frequency)
      })
    },
    addTag(tag) {
      this.eventTagsPreview.push(tag)
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)
      // cancelled
      if (url === null) {
        return
      }
      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
        return
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    addImage() {
      const url = window.prompt('URL')
      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
    editorImageSelected() {
      var file = this.$refs.editorImageInput.files[0]
      if (/image/i.test(file.type)) {
        insertImage(file, this.editor.view, null, this.attachImageFile)
      }
      this.$refs.editorImageInput.value = null
    },
    attachImageFile({ file, onComplete, onFailure }) {
      return this.uploadEditorImageFileEndpoint(file, onComplete, onFailure)
    },
    uploadEditorImageFileEndpoint(file, onComplete, onFailure) {
      this.setLoading('uploadEditorImageFileEndpoint')
      this.clearError('uploadEditorImageFileEndpoint')
      this.global.debug('uploading editor image file')
      let body = new window.FormData()
      body.append('file', file)
      this.axios
        .post(this.$root.apiUrl + '/agitator/files/images/', body)
        .then((response) => {
          this.global.debug(response.data)
          // transform returned URL into CDN url
          const url = response.data.file && response.data.file.url
          const filename = response.data.image && response.data.image.filename
          return onComplete(url, filename, this.global.getCdnImage)
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('uploadEditorImageFileEndpoint')
          this.clearLoading('uploadEditorImageFileEndpoint')
          return onFailure()
        })
        .finally(() => this.clearLoading('uploadEditorImageFileEndpoint'))
    },
    getEventTitlePreviewLength() {
      return this.eventTitlePreview[this.previewLanguage] &&
        this.eventTitlePreview[this.previewLanguage].value
        ? this.eventTitlePreview[this.previewLanguage].value.length
        : 0
    },
    updateEventTicketPreviewTitle(sourceTicket) {
      this.eventTicketsPreview.forEach((ticket) => {
        if (ticket.id === sourceTicket.id) {
          ticket.title = JSON.parse(JSON.stringify(sourceTicket.title))
        }
      })
    },
    updateEventTicketPreviewAmount(sourceTicket) {
      this.eventTicketsPreview.forEach((ticket) => {
        if (ticket.id === sourceTicket.id) {
          ticket.amount = sourceTicket.amount
        }
      })
    },
    saveEventTicketTitle(sourceTicket) {
      this.event.tickets.forEach((ticket) => {
        if (ticket.id === sourceTicket.id) {
          ticket.title = JSON.parse(JSON.stringify(sourceTicket.title))
        }
      })
      this.eventTicketsPreview = JSON.parse(JSON.stringify(this.event.tickets))
    },
    saveEventTicketAmount(sourceTicket) {
      this.event.tickets.forEach((ticket) => {
        if (ticket.id === sourceTicket.id) {
          ticket.amount = sourceTicket.amount
        }
      })
      this.eventTicketsPreview = JSON.parse(JSON.stringify(this.event.tickets))
    },
    saveEventTickets() {
      this.$children.forEach((child) => {
        if (child.$options.name === 'EventTicket') {
          child.saveTicket()
        }
      })
    },
  },
  computed: {
    authHeader() {
      return this.user && this.user.token && 'Bearer ' + this.user.token
    },
    loggedIn() {
      return this.user ? true : false
    },
    mainImageSrc() {
      if (this.mainImage) {
        this.getImagePreview(this.mainImage)
        return this.mainImagePreview
      } else {
        return this.event.main_image
          ? this.global.getCdnImage(this.event.main_image, 1200, 630)
          : null
      }
    },
    isUserEditor() {
      return (
        (this.user && this.user.group_id === '1') ||
        (this.event.scope && this.event.scope.includes('edit'))
      )
    },
    isUserAdmin() {
      return (
        (this.user && this.user.group_id === '1') ||
        (this.event.scope && this.event.scope.includes('admin'))
      )
    },
    isImageUploaderLoading() {
      return (
        (this.loadings['setCampaignMainImageEndpoint'] &&
          this.loadings['setCampaignMainImageEndpoint'] === true) ||
        (this.loadings['uploadImageFileEndpoint'] &&
          this.loadings['uploadImageFileEndpoint'] === true)
      )
    },
    isDeleteEventLoading() {
      return (
        this.loadings['deleteEventEndpoint'] &&
        this.loadings['deleteEventEndpoint'] === true
      )
    },
    isEventLanguageLoading() {
      return (
        this.loadings['saveEventLanguageEndpoint'] &&
        this.loadings['saveEventLanguageEndpoint'] === true
      )
    },
    isEventTitleLoading() {
      return (
        this.loadings['saveEventTitleEndpoint'] &&
        this.loadings['saveEventTitleEndpoint'] === true
      )
    },
    eventTitleFallback() {
      return (
        this.event.title &&
        this.event.title[Object.keys(this.event.title)[0]] &&
        this.event.title[Object.keys(this.event.title)[0]].value
      )
    },
    eventTitle() {
      return (
        this.event.title &&
        this.event.title[this.previewLanguage] &&
        this.event.title[this.previewLanguage].value
      )
    },
    isEventTitleModified() {
      if (this.event.title && this.event.title[this.previewLanguage]) {
        return (
          JSON.stringify(this.event.title[this.previewLanguage]) !==
          JSON.stringify(this.eventTitlePreview[this.previewLanguage])
        )
      } else {
        return (
          this.eventTitlePreview[this.previewLanguage] &&
          this.eventTitlePreview[this.previewLanguage].value !== ''
        )
      }
    },
    isEventShortDescriptionLoading() {
      return (
        this.loadings['saveEventShortDescriptionEndpoint'] &&
        this.loadings['saveEventShortDescriptionEndpoint'] === true
      )
    },
    eventShortDescriptionFallback() {
      return (
        this.event.short_description &&
        this.event.short_description[
          Object.keys(this.event.short_description)[0]
        ] &&
        this.event.short_description[
          Object.keys(this.event.short_description)[0]
        ].value
      )
    },
    eventShortDescription() {
      return (
        this.event.short_description &&
        this.event.short_description[this.previewLanguage] &&
        this.event.short_description[this.previewLanguage].value
      )
    },
    isEventShortDescriptionModified() {
      if (
        this.event.short_description &&
        this.event.short_description[this.previewLanguage]
      ) {
        return (
          JSON.stringify(this.event.short_description[this.previewLanguage]) !==
          JSON.stringify(
            this.eventShortDescriptionPreview[this.previewLanguage]
          )
        )
      } else {
        return (
          this.eventShortDescriptionPreview[this.previewLanguage] &&
          this.eventShortDescriptionPreview[this.previewLanguage].value !== ''
        )
      }
    },
    isEventLongDescriptionLoading() {
      return (
        this.loadings['saveEventLongDescriptionEndpoint'] &&
        this.loadings['saveEventLongDescriptionEndpoint'] === true
      )
    },
    eventLongDescriptionFallback() {
      return (
        this.event.long_description &&
        this.event.long_description[
          Object.keys(this.event.long_description)[0]
        ] &&
        this.event.long_description[Object.keys(this.event.long_description)[0]]
          .value
      )
    },
    eventLongDescription() {
      return (
        this.event.long_description &&
        this.event.long_description[this.previewLanguage] &&
        this.event.long_description[this.previewLanguage].value &&
        this.event.long_description[this.previewLanguage].value !== '<p></p>' &&
        this.event.long_description[this.previewLanguage].value
      )
    },
    isEventLongDescriptionModified() {
      if (
        this.event.long_description &&
        this.event.long_description[this.previewLanguage]
      ) {
        return (
          JSON.stringify(this.event.long_description[this.previewLanguage]) !==
          JSON.stringify(this.eventLongDescriptionPreview[this.previewLanguage])
        )
      } else {
        return (
          this.eventLongDescriptionPreview[this.previewLanguage] &&
          this.eventLongDescriptionPreview[this.previewLanguage].value !== ''
        )
      }
    },
    eventLongDescriptionPreviewValue() {
      return (
        (this.eventLongDescriptionPreview[this.previewLanguage] &&
          this.eventLongDescriptionPreview[this.previewLanguage].value) ||
        ''
      )
    },
    isEventTaglineLoading() {
      return (
        this.loadings['saveEventTaglineEndpoint'] &&
        this.loadings['saveEventTaglineEndpoint'] === true
      )
    },
    eventTaglineFallback() {
      return (
        this.event.tagline &&
        this.event.tagline[Object.keys(this.event.tagline)[0]] &&
        this.event.tagline[Object.keys(this.event.tagline)[0]].value
      )
    },
    eventTagline() {
      return (
        this.event.tagline &&
        this.event.tagline[this.previewLanguage] &&
        this.event.tagline[this.previewLanguage].value
      )
    },
    isEventTaglineModified() {
      if (this.event.tagline && this.event.tagline[this.previewLanguage]) {
        return (
          JSON.stringify(this.event.tagline[this.previewLanguage]) !==
          JSON.stringify(this.eventTaglinePreview[this.previewLanguage])
        )
      } else {
        return (
          this.eventTaglinePreview[this.previewLanguage] &&
          this.eventTaglinePreview[this.previewLanguage].value !== ''
        )
      }
    },
    eventLocationUrl() {
      return (
        this.event.meta &&
        this.event.meta.location &&
        this.event.meta.location.url &&
        this.isValidUrl(this.event.meta.location.url) &&
        this.event.meta.location.url
      )
    },
    eventLocation() {
      return (
        this.event.meta &&
        this.event.meta.location &&
        this.event.meta.location.text
      )
    },
    isEventLocationModified() {
      if (this.event.meta && this.getMetaByKey(this.event.meta, 'location')) {
        return (
          JSON.stringify(this.getMetaByKey(this.event.meta, 'location')) !==
          JSON.stringify(this.eventLocationPreview)
        )
      } else {
        return (
          this.eventLocationPreview.text !== '' ||
          this.eventLocationPreview.url !== ''
        )
      }
    },
    isEventMetaLoading() {
      return (
        this.loadings['saveEventMetaEndpoint'] &&
        this.loadings['saveEventMetaEndpoint'] === true
      )
    },
    isEventLocationLoading() {
      return (
        this.loadings['saveEventLocationEndpoint'] &&
        this.loadings['saveEventLocationEndpoint'] === true
      )
    },
    eventExternalLinkUrl() {
      return (
        this.event.meta &&
        this.event.meta.externalLink &&
        this.event.meta.externalLink.url &&
        this.isValidUrl(this.event.meta.externalLink.url) &&
        this.event.meta.externalLink.url
      )
    },
    eventExternalLinkText() {
      return (
        this.event.meta &&
        this.event.meta.externalLink &&
        this.event.meta.externalLink.text
      )
    },
    isEventExternalLinkModified() {
      if (
        this.event.meta &&
        this.getMetaByKey(this.event.meta, 'externalLink')
      ) {
        return (
          JSON.stringify(this.getMetaByKey(this.event.meta, 'externalLink')) !==
          JSON.stringify(this.eventExternalLinkPreview)
        )
      } else {
        return (
          this.eventExternalLinkPreview.text !== '' ||
          this.eventExternalLinkPreview.url !== ''
        )
      }
    },
    isEventExternalLinkLoading() {
      return (
        this.loadings['saveExternalLinkEndpoint'] &&
        this.loadings['saveExternalLinkEndpoint'] === true
      )
    },
    mainImageCaption() {
      return this.event.meta && this.event.meta.mainImageCaption
    },
    isMainImageCaptionModified() {
      if (
        this.event.meta &&
        this.getMetaByKey(this.event.meta, 'mainImageCaption')
      ) {
        return (
          JSON.stringify(
            this.getMetaByKey(this.event.meta, 'mainImageCaption')
          ) !== JSON.stringify(this.mainImageCaptionPreview)
        )
      } else {
        return this.mainImageCaptionPreview !== ''
      }
    },
    isMainImageCaptionLoading() {
      return (
        this.loadings['saveMainImageCaptionEndpoint'] &&
        this.loadings['saveMainImageCaptionEndpoint'] === true
      )
    },
    eventTicketLinkUrl() {
      return (
        this.event.meta &&
        this.event.meta.ticketLink &&
        this.event.meta.ticketLink.url &&
        this.isValidUrl(this.event.meta.ticketLink.url) &&
        this.event.meta.ticketLink.url
      )
    },
    isEventTicketLinkModified() {
      if (this.event.meta && this.getMetaByKey(this.event.meta, 'ticketLink')) {
        return (
          JSON.stringify(this.getMetaByKey(this.event.meta, 'ticketLink')) !==
          JSON.stringify(this.eventTicketLinkPreview)
        )
      } else {
        return this.eventTicketLinkPreview.url !== ''
      }
    },
    isEventTicketLinkLoading() {
      return (
        this.loadings['saveTicketLinkEndpoint'] &&
        this.loadings['saveTicketLinkEndpoint'] === true
      )
    },
    isEventDateLoading() {
      return (
        this.loadings['saveEventDateEndpoint'] &&
        this.loadings['saveEventDateEndpoint'] === true
      )
    },
    isEventDateModified() {
      if (this.event.meta && this.getMetaByKey(this.event.meta, 'date')) {
        return (
          JSON.stringify(this.getMetaByKey(this.event.meta, 'date')) !==
          JSON.stringify(this.eventDatePreview)
        )
      } else {
        return (
          this.eventDatePreview.start !== '' || this.eventDatePreview.end !== ''
        )
      }
    },
    eventStartDate() {
      return (
        this.event.meta && this.event.meta.date && this.event.meta.date.start
      )
    },
    eventDateHour() {
      return (
        this.event.meta && this.event.meta.date && this.event.meta.date.hour
      )
    },
    eventEndDate() {
      return this.event.meta && this.event.meta.date && this.event.meta.date.end
    },
    eventDateType() {
      return (
        this.event.meta && this.event.meta.date && this.event.meta.date.type
      )
    },
    localeCode() {
      // if (this.language.toLowerCase() === 'en') {
      //   return 'en-US'
      // }
      // if (this.language.toLowerCase() === 'is') {
      //   return 'is' // NOTE: will not work in Chrome, icelandic locale is not supported
      // }
      // default fallback
      return 'en-GB'
    },
    eventStage() {
      return this.event.stage
    },
    isEventStageModified() {
      return this.event.stage !== this.eventStagePreview
    },
    isEventStageLoading() {
      return (
        this.loadings['saveEventStageEndpoint'] &&
        this.loadings['saveEventStageEndpoint'] === true
      )
    },
    availableCategories() {
      return this.categories.filter((category) => {
        return (
          this.eventCategoriesPreview.findIndex(
            (categoryPreview) => categoryPreview.id === category.id
          ) === -1 && category.active !== 0
        )
      })
    },
    eventCategories() {
      return this.event.categories
    },
    isEventCategoriesModified() {
      return (
        JSON.stringify(this.event.categories) !==
        JSON.stringify(this.eventCategoriesPreview)
      )
    },
    isEventCategoriesLoading() {
      return (
        this.loadings['saveEventCategoriesEndpoint'] &&
        this.loadings['saveEventCategoriesEndpoint'] === true
      )
    },
    eventPopularTags() {
      return (
        this.popularTags.length > 0 &&
        this.sortEventTagsByFrequency(this.popularTags).slice(0, 10)
      )
    },
    eventTags() {
      return this.event.meta && this.event.meta.tags
    },
    isEventTagsModified() {
      if (this.event.meta && this.getMetaByKey(this.event.meta, 'tags')) {
        return (
          JSON.stringify(this.getMetaByKey(this.event.meta, 'tags')) !==
          JSON.stringify(this.eventTagsPreview)
        )
      } else {
        return this.eventTagsPreview.length > 0
      }
    },
    isEventTagsLoading() {
      return (
        this.loadings['saveEventTagsEndpoint'] &&
        this.loadings['saveEventTagsEndpoint'] === true
      )
    },
    isEditorFocused() {
      return this.editor ? this.editor.isFocused : false
    },
    eventUrlType() {
      if (window.location.pathname.indexOf('/events/') !== -1) {
        if (window.location.pathname.indexOf('/admin') !== -1) {
          return 'admin'
        }
        if (window.location.pathname.indexOf('/edit') !== -1) {
          return 'editor'
        }
        return 'view'
      }
      return false
    },
    hasUnsavedChanges() {
      return (
        this.isEventDateModified ||
        this.isEventTagsModified ||
        this.isEventStageModified ||
        this.isEventTitleModified ||
        this.isEventTaglineModified ||
        this.isEventLocationModified ||
        this.isEventCategoriesModified ||
        this.isEventExternalLinkModified ||
        this.isMainImageCaptionModified ||
        this.isEventTicketLinkModified ||
        this.isEventLongDescriptionModified ||
        this.isEventShortDescriptionModified ||
        this.mainImagePreview ||
        this.isEventTicketsModified
      )
    },
    isReviewRequested() {
      return this.event.meta && this.event.meta.requestReview
    },
    isReviewRequestedLoading() {
      return (
        this.loadings['requestReviewEndpoint'] &&
        this.loadings['requestReviewEndpoint'] === true
      )
    },
    isResetRequestReviewLoading() {
      return (
        this.loadings['resetRequestReviewEndpoint'] &&
        this.loadings['resetRequestReviewEndpoint'] === true
      )
    },
    isPublishEventLoading() {
      return (
        this.loadings['publishEventEndpoint'] &&
        this.loadings['publishEventEndpoint'] === true
      )
    },
    isEventExternalLinkUrlFacebook() {
      return (
        this.eventExternalLinkUrl &&
        /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(.?)?]/.test(
          this.eventExternalLinkUrl
        )
      )
    },
    isEventTicketsModified() {
      if (this.event.tickets) {
        return (
          JSON.stringify(this.event.tickets) !==
          JSON.stringify(this.eventTicketsPreview)
        )
      } else {
        return JSON.stringify(this.eventTicketsPreview) !== JSON.stringify([])
      }
    },
    canEditEvent() {
      return this.isUserAdmin || (this.isUserEditor && this.event.stage < 2)
    },
  },
  mounted() {
    this.event = JSON.parse(JSON.stringify(this.campaignProp))
    this.popularTags = JSON.parse(JSON.stringify(this.popularTagsProp))
    this.$nextTick(() => {
      this.previewHeight =
        this.$refs.mainImagePreview &&
        this.$refs.mainImagePreview.clientHeight + 'px'
    })
    this.previewLanguage = this.language
    this.eventTitlePreview = JSON.parse(JSON.stringify(this.event.title))
    this.eventShortDescriptionPreview = JSON.parse(
      JSON.stringify(this.event.short_description)
    )
    this.eventLongDescriptionPreview = JSON.parse(
      JSON.stringify(this.event.long_description)
    )
    this.eventTaglinePreview = JSON.parse(JSON.stringify(this.event.tagline))
    this.eventLocationPreview = this.getMetaByKey(this.event.meta, 'location')
      ? this.getMetaByKey(this.event.meta, 'location')
      : this.eventLocationPreview
    this.eventExternalLinkPreview = this.getMetaByKey(
      this.event.meta,
      'externalLink'
    )
      ? this.getMetaByKey(this.event.meta, 'externalLink')
      : this.eventExternalLinkPreview
    this.mainImageCaptionPreview = this.getMetaByKey(
      this.event.meta,
      'mainImageCaption'
    )
      ? this.getMetaByKey(this.event.meta, 'mainImageCaption')
      : this.mainImageCaptionPreview
    this.eventTicketLinkPreview = this.getMetaByKey(
      this.event.meta,
      'ticketLink'
    )
      ? this.getMetaByKey(this.event.meta, 'ticketLink')
      : this.eventTicketLinkPreview
    this.eventDatePreview = this.getMetaByKey(this.event.meta, 'date')
      ? this.getMetaByKey(this.event.meta, 'date')
      : this.eventDatePreview
    this.eventStagePreview = this.event.stage
    this.eventCategoriesPreview = JSON.parse(
      JSON.stringify(this.event.categories)
    )
    this.eventTagsPreview = this.getMetaByKey(this.event.meta, 'tags')
      ? this.getMetaByKey(this.event.meta, 'tags')
      : this.eventTagsPreview
    this.eventTicketsPreview = this.event.tickets
      ? JSON.parse(JSON.stringify(this.event.tickets))
      : []
    this.categories = JSON.parse(JSON.stringify(this.categoriesProp))
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'px-3',
        },
      },
      content: this.eventLongDescriptionPreviewValue,
      extensions: [
        Document,
        History,
        Paragraph,
        Text,
        Bold,
        Italic,
        Heading,
        OrderedList,
        BulletList,
        ListItem,
        Link.configure({
          openOnClick: false,
        }),
        // Image,
        CustomImage.configure({ attachImageFile: this.attachImageFile }),
      ],
      onUpdate: () => {
        const value = this.editor.getHTML()
        // empty editor contains a p tag so we remove it
        this.setEventLongDescriptionPreview(value !== '<p></p>' ? value : '')
      },
    })
  },
  beforeDestroy() {
    this.editor.destroy()
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  watch: {
    eventLongDescriptionPreviewValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
}
</script>

<style scoped lang="scss">
.image-preview {
  object-fit: cover;
}
.editor-buttons {
  z-index: 99;
}
.tiptap-editor {
  border: 1px solid #ced4da;

  //   &.focused {
  //     color: #495057;
  //     background-color: #fff;
  //     border-color: #80bdff;
  //     outline: 0;
  //     box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  //   }
}
</style>

<style lang="scss">
.ProseMirror-focused {
  outline: none;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;

  &.ProseMirror-selectednode {
    outline: 3px solid #68cef8;
  }

  &.preview {
    animation: pulse 2s infinite;
  }
}
.editor-content img {
  max-width: 100%;
  height: auto;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
